<section id="{$prefix}-section-driving" class="section-container container-fluid shadow-sm">
    <HiddenAnchor markerName="{'driving'}" />
    <h2 class="text-center">
        <Icon iconName="checklist" iconClass="heading" />
        {$actualDriving.title}
    </h2>
    <p class="text-center mb-4"> {@html $actualDriving.text}</p>
    <div class="steps row">
        {#each steps as step, i}
            <div class="step-wrapper col-sm-12 col-lg-6">
                <div class="step">
                    <Icon iconName="checked-box-colored" iconClass="heading" />
                    <p class="step-text pr-3">{step}</p>
                </div>
            </div>
        {/each}
    </div>
    <div class="illustration-wrapper text-center p-5">
        <Icon iconName="illustration-driving" iconClass="fill" />
    </div>
</section>

<script lang="ts">
    import { prefix, actualDriving } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';

    $: steps = $actualDriving.steps || [];
    const inViewport = ()=> {

    }

    onMount(() => {

    });


</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/page.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection($ColorHighlight, #ffffff);
  }

  .step-wrapper {
    padding: 2rem;
  }

  .step {
    background-color: white;
    color: $ColorPrimary;
    padding: 2rem;
    height: 100%;
  }

</style>
