<section id="{$prefix}-section-road-to-license" class="section-container container-fluid shadow-sm">
    <HiddenAnchor markerName="{'road-to-license'}" />
    <h2 class="text-center">
        <Icon iconName="checklist" iconClass="heading" />
        {$roadToLicense.title}
    </h2>
    <p class="text-center mb-4"> {@html $roadToLicense.text}</p>
    <div class="phases d-flex justify-content-around flex-wrap">
        {#each phases as phase, i}
            <div class="card phase shadow-lg">
                <div class="card-header phase__heading pb-3">
                    <Icon iconName="checked-box-white" iconClass="heading" /> {phase.heading}
                </div>
                <div class="card-body phase__description">
                    <p class="card-text">{phase.description}</p>
                </div>
            </div>
        {/each}
        <div class="phase text-center">
            <Icon iconName="illustration-road-to-license" iconClass="fill" />
        </div>
    </div>
</section>

<script lang="ts">
    import { prefix, roadToLicense } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';


    $: phases = $roadToLicense.phases || [];
    const inViewport = ()=> {

    }

    onMount(() => {

    });



    // const unsubscribe = questions.subscribe(loadedQuestions => {
    //     console.log(loadedQuestions);
    // 	loadedQuestions.forEach( (q) => {
    // 		let extendQuestion = q;
    // 		extendQuestion.answers.forEach( (extendAnswer) => {
    // 			extendAnswer["selected"] = false;
    // 		});
    // 		savedAnswers.push(extendQuestion);
    // 	});
    // 	console.log(savedAnswers);
    // });

    // onDestroy(() => {
    // 	// 4. We make sure to unsubscribe from the store once the component has been destroyed.
    // 	unsubscribe();
    // });

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection($ColorSecondary, #ffffff);
  }

  .phases {
    margin-top: 1rem;
  }

  .phase {
    width: 95%;
    margin: 1rem;
    @include largeUp {
      width: 45%;
    }
    &__heading {
      background-color: $ColorPrimary;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: solid 1px $ColorSecondary;
     }
    &__description {
        color: $ColorDark;
    }
  }
</style>
