<script>
    import { assetUrl, language, availableLanguages, prefix, i18n } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';

    import SelectComponent from './SelectComponent.svelte';

    let defaultLanguage = language;

    const switchClass = $prefix + '-language-selector';

    function setLanguage(event) {
        console.log(event);
        language.set(event.detail.newValue);
    }

</script>


<SelectComponent options={$availableLanguages}
                 classNames={switchClass}
                 display_func={o => '<span class="flag-icon flag-icon-' + o + '"></span>' + o}
                 bind:value={defaultLanguage}
                 on:optionSelected={setLanguage}/>


<style type="text/scss">
  @import '../sass/vars';
  @import '../sass/mixins';

</style>
