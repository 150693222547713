<script>
	import { prefix } from '../stores.js';

	import { createEventDispatcher } from 'svelte';

	export let buttonClass = '';
    export let buttonText;

    export let buttonLink;
	export let buttonBlink = false;

    export let blankTarget = true;

	const dispatch = createEventDispatcher();

	$: buttonClasses = $prefix + '-button text-center ' + (buttonClass !== '' ? $prefix + '-button--' + buttonClass : '');

	function buttonClicked( event ) {
		dispatch('buttonClicked');
	}

</script>

{#if buttonLink && buttonLink.length }
    <a href="{buttonLink}" target="{blankTarget}" class="{buttonClasses} {buttonBlink ? 'blink' : ''}" on:click={buttonClicked}>
        <span>
            {buttonText}
        </span>
    </a>
{:else}
    <span class="{buttonClasses} {buttonBlink ? 'blink' : ''}" on:click={buttonClicked}>
        {buttonText}
    </span>
{/if}


<style type="text/scss">
    @import '../sass/vars';
    @import '../sass/mixins';

    .defaultStyles { // todo: remove?
      background-color: $ColorPrimary;
      color: white;
      cursor: pointer;
      display: inline-block;
      border-radius: 2.25rem;
      line-height: 1.25rem;
      font-size: 1.125rem;
      padding: 0.75rem 1.75rem;
      text-transform: uppercase;
    }

	.#{$prefix}-button {
		background-color: $ColorPrimary;
		color: white;
		cursor: pointer;
		display: inline-block;
		border-radius: 2.25rem;
		line-height: 1.25rem;
        font-size: 1.125rem;
        padding: 0.75rem 1.75rem;
        text-transform: uppercase;
        text-decoration: none;

        @include tabletUp() {
          padding: 0.875rem 1.875rem;
          font-size: 1.265rem;
        }

		&.blink {
			animation: spark 3s infinite normal;
		}

		&--disabled {
			animation: none;
			opacity: 50%;
			pointer-events: none;
		}

		&--dark {
			background-color: $ColorDark;
		}

	}
</style>
