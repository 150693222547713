<div class="app-container">
    <Icons />
    <Header />
    <Menu />
    <Home />
    <RoadToLicense />
    <Theory />
    <Driving />
    <Prices />
    <Contact />
    <Impressum />
    <Footer />
</div>

<script lang="ts">
    import { assetUrl, language, prefix, i18n, multilingual } from './stores.js';
    import { onMount, onDestroy } from 'svelte';
    import 'sass/page.scss';
    import viewport from './utils/useViewportAction.js';

    import Header from './components/Header.svelte';
    import Icons from './components/Icons.svelte';
    import Menu from './components/Menu.svelte';
    import Home from './sections/Home.svelte';
    import RoadToLicense from './sections/RoadToLicense.svelte';
    import Theory from './sections/Theory.svelte';
    import Driving from './sections/Driving.svelte';
    import Prices from './sections/Prices.svelte';
    import Contact from './sections/Contact.svelte';
    import Impressum from './sections/Impressum.svelte';
    import Footer from "./components/Footer.svelte";

    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';


    export let assets;
    export let languageCode;
    export let i18nSetName;
    export let multilingualMode;

    onMount(() => {
        i18n.set(window[i18nSetName]);
        assetUrl.set(assets);
        language.set(languageCode);
        multilingual.set(multilingualMode);
    });



    // const unsubscribe = questions.subscribe(loadedQuestions => {
    //     console.log(loadedQuestions);
    // 	loadedQuestions.forEach( (q) => {
    // 		let extendQuestion = q;
    // 		extendQuestion.answers.forEach( (extendAnswer) => {
    // 			extendAnswer["selected"] = false;
    // 		});
    // 		savedAnswers.push(extendQuestion);
    // 	});
    // 	console.log(savedAnswers);
    // });

    // onDestroy(() => {
    // 	// 4. We make sure to unsubscribe from the store once the component has been destroyed.
    // 	unsubscribe();
    // });

</script>

<style type="text/scss">
  @import "sass/vars.scss";
  @import "sass/page.scss";
  @import "sass/mixins.scss";

  :global(#app-container) {
    @include pageSection($ColorHighlight);
  }
</style>
