<div class="hidden-anchor">
    <span id="{anchorId}">&nbsp;hier</span>
</div>

<script lang="ts">
    import { prefix } from '../stores.js';
    export let markerName: string = '';
    $: anchorId = $prefix + '-anchor-' + markerName;

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .hidden-anchor {
    opacity: 0;
    height: 1px;
    width: 100vw;
    position: absolute;
    left: 0;
    top: $headerMobileHeight * -1;
    @include xLargeUp {
      top: $headerDesktopHeight * -1;
    }
  }

</style>
