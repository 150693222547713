<div class="background-filler d-flex justify-content-between">
    <span class="background-filler__item" in:fade="{{duration: 400, opacity: 1, start: 0, easing: quartIn}}"><Icon iconName="background-learn" iconClass="shrink" /></span>
    <span class="" in:fade="{{duration: 600, opacity: 1, start: 0, easing: quartIn}}"><Icon iconName="background-wheel" iconClass="shrink" /></span>
    <span class="" in:fade="{{duration: 800, opacity: 1, start: 0, easing: quartIn}}"><Icon iconName="background-finish" iconClass="shrink" /></span>
    <span class="" in:fade="{{duration: 1000, opacity: 1, start: 0, easing: quartIn}}"><Icon iconName="background-drive" iconClass="shrink" /></span>
</div>

<script lang="ts">
    import Icon from '../components/Icon.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .background-filler {
    min-height: 1rem;
    @include xLargeUp {
      margin-top: 4rem;
    }
    &__item {
      display: inline-block;
      padding: 1rem;
    }
  }

</style>
