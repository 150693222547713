import { writable, readable, derived } from 'svelte/store';

export const assetUrl = writable('');
export const language = writable('de');

export const multilingual = writable(true);
export const delayShort = readable(300);
export const delayMedium = readable(400);
export const delayLong = readable(1200);
export const prefix = readable('x818973c5df76821320be3bb174285ef4');
export const i18n = writable({});

const fallbackValues = {
    de: {
        infoNotAvailable: "Diese Information ist derzeit nicht verfügbar. Bitte an einem anderen Tag noch einmal nachschauen oder <span class='contact-link'>uns direkt für Anfragen kontaktieren.</span>",
    },
    en: {
        infoNotAvailable: "This information is currently not available. Please check back on another day or <span class='contact-link'>contact us directly for inquiries.</span>",
    },
    tr: {
        infoNotAvailable: "Bu bilgi şu anda mevcut değildir. Lütfen başka bir gün tekrar kontrol edin veya <span class='contact-link'>sorularınız için doğrudan bizimle iletişime geçin.</span>",
    }
}

export let availableLanguages = derived([i18n], ([$i18n])=> {
    let options = [];

    if($i18n) {
        Object.keys($i18n).forEach((keyName) => {
            if($i18n[keyName].showLanguage) {
                options.push(keyName)
            }
        });
    }
    return options;
});

export let roadToLicense = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].roadToLicense;
    }
    return {};
});

export let supportViaApp = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].supportViaApp;
    }
    return null;
});
export let theoryClasses = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].theoryClasses ?? $i18n[$language].theoryClasses;
    }
    const notAvailable = fallbackValues[$language].infoNotAvailable;
    return {schedules: [notAvailable ?? '']};
});

export let topics = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].theoryClasses.topics ?? $i18n[$language].theoryClasses.topics;
    }
    const notAvailable = fallbackValues[$language].infoNotAvailable;
    return {topics: [notAvailable ?? '']};
});

export let topicsClassA = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].theoryClasses.topicsClassA ?? $i18n[$language].theoryClasses.topicsClassA;
    }
    const notAvailable = fallbackValues[$language].infoNotAvailable;
    return {topicsClassA: [notAvailable ?? '']};
});

export let topicsClassB = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].theoryClasses.topicsClassB ?? $i18n[$language].theoryClasses.topicsClassB;
    }
    const notAvailable = fallbackValues[$language].infoNotAvailable;
    return {topicsClassB: [notAvailable ?? '']};
});
export let actualDriving = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].actualDriving;
    }
    return {};
});

export let prices = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].prices;
    }
    return {};
});

export let contact = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].contact;
    }
    return {};
});

export let contactForImpressum = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].impressum?.contactDetails ?? [];
    }
    return [];
});

export let menuEntries = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].menuEntries;
    }
    return [];
});

/* TODO: Change to Site Settings */
export let siteTitle = derived([i18n, language], ([$i18n, $language])=> {
    if($i18n && $i18n[$language]) {
        return $i18n[$language].siteTitle;
    }
    return '';
});
