<footer class="page-footer container-fluid p-3">
    <p class="copyright text-center">&copy;Copyright {year} <span class="copyright__title">{$siteTitle}</span></p>
</footer>


<script lang="ts">
    import { siteTitle } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';

    let year = (new Date()).getFullYear();

    onMount(() => {

    });

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .page-footer {
    min-height: 60px;
    background-color: $ColorDark;
    color: #ffffff;
    font-size: 14px;
  }

  .copyright {
    opacity: 0.5;
    padding: 0.75rem;
    &__title {
      margin-left: 1rem;
    }
  }
</style>
