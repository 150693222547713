<script>
    import { prefix, language, i18n } from '../stores.js';
    import { createEventDispatcher } from 'svelte';
    export let options = [];
    export let display_func = a => a;
    export let index = 0;
    export let value;

    export let classNames = '';


    const dispatch = createEventDispatcher();

    $: {
        value = options[index];
    }

    function optionSelected(event) {
        dispatch('optionSelected', {
            newValue: value
        });
    }
</script>

<select class="form-select {classNames}" data-width="fit" bind:value={index} on:change={(event) => ( optionSelected(event) )}>
    {#each options as option, i}
        <option value={i}>{@html  display_func(option)}</option>
    {/each}
</select>


<style type="text/scss">
  @import '../sass/vars';
  @import '../sass/mixins';
</style>
