<section id="{$prefix}-section-contact" class="section-container container-fluid shadow-sm">
    <HiddenAnchor markerName="{'contact'}" />
    <div class="row">
        <div class="main col-sm-12 col-lg-6">
            <h2 class="text-center">
                <Icon iconName="checklist" iconClass="heading" />
                {$contact.title}
            </h2>
            <p class="text-center mb-4"> {@html $contact.text}</p>
            <div class="main__details row justify-content-around">
                <div class="main__details__left col-sm-12 col-lg-6">
                    <p class="text-bold">{$contact.addressLabel}</p>
                    <p>{$contact.addressStreet}<br />{$contact.addressCity}</p>
                </div>
                <div class="main__details__right col-sm-12 col-lg-6">
                    <p>{@html $contact.tel}</p>
                    <p>{@html $contact.email}</p>
                </div>
            </div>
        </div>
        <div class="map col-sm-12 col-lg-6 px-2">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.0168585011525!2d7.659524712501443!3d51.58625440482184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b9128de36cf555%3A0x8e1d38d29e028553!2sBahnhofstra%C3%9Fe%2034A%2C%2059174%20Kamen!5e0!3m2!1sen!2sde!4v1692668718026!5m2!1sen!2sde" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</section>

<script lang="ts">
    import { prefix, contact } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';
    const inViewport = ()=> {

    }

    onMount(() => {

    });


</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection($ColorDark, #ffffff);
  }

  .main {
    @include largeUp {
      padding: 4rem;
    }
    &__details {
      justify-content: space-around;
      @include largeUp {
        margin: 4rem 2rem 2rem 2rem;
      }

      &__left {

      }
      &__right {

      }
    }
  }

  .map {
    margin-top: 2rem;
    @include largeUp {
      padding: 2rem;
      min-width: 35vw;
    }
  }

</style>
