import 'core-js/stable/promise';
import 'core-js/es/object/assign';
import 'core-js/es/array/fill';
import 'core-js/es/array/from';
import App from './App.svelte';

const appContainer = document.getElementById('app-container');

const app = new App({
    target: appContainer,
    props: {
        assets : appContainer.dataset.assets,
        languageCode : appContainer.dataset.lang,
        i18nSetName: appContainer.dataset.i18n,
        multilingualMode: appContainer.dataset.multilingual === 'true' ? true : false
    }
});

console.info('App loading...');
export default app;