<section id="{$prefix}-section-home" class="section-container container-fluid d-flex flex-column justify-space-between">
    <HiddenAnchor markerName="{'home'}" />
    {#if showHero}
    <div class="hero" in:fly="{{y:300, duration:$delayLong}}">
        <div class="hero__backdrop bg-white"></div>
        <div class="hero__content">
            <h1 class="hero__text">{@html $i18n[$language].heroText }</h1>
            <Button class="hero__cta" buttonText={ $i18n[$language].heroCTA } buttonBlink="{true}" buttonLink={ $i18n[$language].heroCTATarget } />
        </div>
    </div>
    {/if}
    <div id="stageCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="{$assetUrl}images/slider/1.jpg" class="img-fluid" alt="Responsive image">
            </div>
            <div class="carousel-item">
                <img src="{$assetUrl}images/slider/2.jpg" class="img-fluid" alt="Responsive image">
            </div>
            <div class="carousel-item">
                <img src="{$assetUrl}images/slider/3.jpg" class="img-fluid" alt="Responsive image">
            </div>
            <div class="carousel-item">
                <img src="{$assetUrl}images/slider/4.jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    </div>
    <div class="mobile_filler">
        <FillerRow />
    </div>
</section>

<script lang="ts">
    import { assetUrl, delayLong, language, prefix, i18n } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';

    import Button from '../components/Button.svelte';
    import FillerRow from '../components/FillerRow.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

    let showHero = false;

    onMount(() => {
        setTimeout(()=>{ showHero = true; }, 1000);
    });



    // const unsubscribe = questions.subscribe(loadedQuestions => {
    //     console.log(loadedQuestions);
    // 	loadedQuestions.forEach( (q) => {
    // 		let extendQuestion = q;
    // 		extendQuestion.answers.forEach( (extendAnswer) => {
    // 			extendAnswer["selected"] = false;
    // 		});
    // 		savedAnswers.push(extendQuestion);
    // 	});
    // 	console.log(savedAnswers);
    // });

    // onDestroy(() => {
    // 	// 4. We make sure to unsubscribe from the store once the component has been destroyed.
    // 	unsubscribe();
    // });

    function goTo(target: string = '') {
        const el = document.getElementById($prefix + '-' + target);
        if(el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start"
            });
        }
    }

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include pageSection(#ffffff);
  }

  :global(#stageCarousel) {
    width: 100%;
    // max-width: 700px;
    max-height: 75vh;
    margin-top: 2rem;
    @include tabletUp {
      margin-top: 0;
    }
  }

  .hero {
    width: 100%;
    padding: 0 1rem 1rem 2rem;
    &__text {
      font-size: 1.25rem;
      margin-bottom: 2rem;
      padding-top: 3.5rem;
      @include tabletUp {
        padding-top: 0;
      }
    }
  }

  .mobile_filler {
    padding: 3rem 1rem;
  }

  @include tabletUp {
    .hero {
      width: 100%;
      height: 50%;
      max-width: 520px;
      min-height: 480px;
      padding: 3rem 2rem;
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 100;
      &__backdrop {
        opacity: 80%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &__content {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        padding: 5rem;
        margin-bottom: 2rem;
      }
      &__text {
        margin-bottom: 2rem;
        font-size: 1.625rem;
      }
    }

    .mobile_filler {
      position: absolute;
      bottom: 0;
    }

    @include largeUp {
      .mobile_filler {
        display: none;
      }
    }
  }



</style>
