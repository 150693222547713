<div class="site-menu">
    <div on:click={toggleMenu} class="site-menu__control d-flex justify-content-center" transition:fade="{{duration: 800, opacity: 1, start: 0, easing: quartIn}}">
        <div class="divider">
            <Icon iconName="traffic-light" iconClass="" />
        </div>
        {#if !showMenu }
            <Icon iconName="menu" iconClass="" />
        {:else}
            <span class="close {transitioning ? 'close--transitioning' : ''}">&times;</span>
        {/if}
    </div>
    {#if showMenu }
        <div class="site-menu__contents shadow" transition:fly={{x: 540, opacity: 1}}>
            {#if $multilingual}
                <div class="language-switch-wrapper">
                    <LanguageSwitch />
                </div>
            {/if}
            <ul class="site-menu__items list-group">
                {#each items as item, i}
                    <li class="site-menu__item list-group-item d-flex align-items-center" on:click={()=>{goTo(item.target)}}>
                        <Icon iconName="{item.target}" iconClass="menu" />
                        <div class="site-menu__item__text">{item.title}</div>
                    </li>
                {/each}
            </ul>
        </div>
    {/if}
</div>
<script lang="ts">
    import { delayMedium, delayLong, delayShort, prefix, multilingual, menuEntries } from '../stores.js';
    import Icon from '../components/Icon.svelte';
    import { fade, slide, fly } from 'svelte/transition';
    import { quartIn } from 'svelte/easing';
    import LanguageSwitch from "./LanguageSwitch.svelte";

    let showMenu = false;
    let transitioning = false;
    $: items = $menuEntries && $menuEntries.length ? $menuEntries : [
        {
            title: 'Start',
            target: 'home',
        },
        {
            title: 'Der Weg zu deinem Führerschein',
            target: 'road-to-license'
        },
        {
            title: 'Theorie Unterricht',
            target: 'theory'
        },
        {
            title: 'Training App',
            target: 'theory-app'
        },
        {
            title: 'Fahrstunden',
            target: 'driving'
        },
        {
            title: 'Unsere Preise',
            target: 'prices'
        },
        {
            title: 'Kontakt und Standort',
            target: 'contact'
        },
        {
            title: 'Impressum',
            target: 'impressum'
        },
    ];


    function toggleMenu() {
        showMenu = !showMenu;
    }

    function goTo(target: string = '') {
        transitioning = true;
        window.setTimeout(()=>{
            showMenu = false;
        }, $delayMedium);
        window.setTimeout(()=>{
            const el = document.getElementById($prefix + '-anchor-' + target);
            if(el) {
                el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start"
                });
            }
            transitioning = false;
        }, $delayLong);
    }

</script>

<style type="text/scss">
  @import '../sass/vars';
  @import '../sass/mixins';

  .site-menu {
    &__control {
      position: fixed;
      // top: $headerMobileHeight;
      top: 1rem;
      right: 1rem;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: map-get($z-index, nav-control);
      width: 60px;
      height: 50px;
      cursor: pointer;
      border-radius: 10px;

      @include largeUp {
        top: 1rem;
      }

      .divider {
        margin-right: -1.25rem;
      }

      .close {
        font-size: 2rem;
        font-weight: 300;
        opacity: 0.3;
        line-height: 20px;
        border: solid 1px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        margin-top: 8px;
        margin-left: 10px;
        margin-right: 1rem;
        &--transitioning {
          animation: spin 3s infinite normal;
        }
      }

      &:hover {
        // border-bottom: solid 1px $ColorHighlight;
        // box-shadow: $boxShadow;
        animation: spin 2s 1 normal;
        .close {
          opacity: 1;
          color: $ColorHighlight;
          border-color: $ColorPrimary;
        }
      }


      @include largeUp {
        top: 2rem;
      }
    }
    &__contents {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      overflow-y: scroll;
      background-color: white;
      color: white;
      z-index: map-get($z-index, nav-content);
      @include tabletUp {
        width:40%;
      }
    }
    &__items {
      margin-top: $headerMobileHeight;
      padding: -1rem 0 2rem;
      @include tabletUp {
        margin-top: $headerDesktopHeight;
      }
    }
    &__item {
      background: transparent;
      padding: 0.5rem 1.25rem;
      cursor: pointer;
      &:hover {
        background-color: $ColorHighlight;
        color: white;
        animation: spark-reverse 3s infinite normal;
      }
      &__text {
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }

    .language-switch-wrapper {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
  }

</style>
