<div class="page-header container-fluid sticky-top shadow p-3">
    <div class="logo container-fluid d-flex justify-start"  on:click={()=>{goToTop()}}>
        <img src="{$assetUrl}images/logo-icon.png" class="logo__icon {transitioning ? 'logo__icon--transitioning': ''}" alt="Responsive image">
        <img src="{$assetUrl}images/logo-text.png" class="logo__text" alt="Responsive image">
    </div>
</div>

<script lang="ts">
    import { assetUrl, delayLong, prefix, i18n } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Menu from '../components/Menu.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

    let transitioning = false;

    onMount(() => {

    });

    function goToTop() {
        transitioning = true;
        window.setTimeout(()=>{
            const el = document.getElementById($prefix + '-anchor-home');
            if(el) {
                el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start"
                });
            }
            transitioning = false;
        }, $delayLong);
    }


    // const unsubscribe = questions.subscribe(loadedQuestions => {
    //     console.log(loadedQuestions);
    // 	loadedQuestions.forEach( (q) => {
    // 		let extendQuestion = q;
    // 		extendQuestion.answers.forEach( (extendAnswer) => {
    // 			extendAnswer["selected"] = false;
    // 		});
    // 		savedAnswers.push(extendQuestion);
    // 	});
    // 	console.log(savedAnswers);
    // });

    // onDestroy(() => {
    // 	// 4. We make sure to unsubscribe from the store once the component has been destroyed.
    // 	unsubscribe();
    // });

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .page-header {
    background: white;
    // background: linear-gradient(90deg, rgba(35,21,44,1) 1%, rgba(151,56,113,1) 100%);
    border-bottom: solid 2px $ColorSecondary;
    height: $headerMobileHeight;

    .logo {
      width: 100%;
      overflow: hidden;
      margin-left: -20px;
      cursor: pointer;
      &__icon, &__text {
        position: relative;
        height: 50px;
        width: auto;
      }
      &__icon {
        height: 50px;
        &--transitioning {
          animation: spin 3s infinite normal;
        }
      }
      &__text {
        height: 120px;
        margin-top: -40px;
        margin-left: -10px;
      }
    }

    @include largeUp {
      height: $headerDesktopHeight;
      .logo {
        min-width: 50%;
        overflow: hidden;
        &__icon {
          margin-right: -1rem;
          height: 80px;
        }
        &__text {
          height: 240px;
          margin-top: -75px;
          margin-left: 0.75rem;
        }
      }
    }

  }


</style>
