<section id="{$prefix}-section-theory"
         class="section-container container-fluid shadow-sm d-flex justify-content-around flex-wrap">
    <HiddenAnchor markerName="{'theory'}" />
    <div class="image-wrapper">
        <div class="illustration-wrapper text-center">
            <Icon iconName="illustration-classes" iconClass="fill" />
        </div>
    </div>
    <div class="content-wrapper">
        <h2 class="text-center">
            <Icon iconName="checklist-colored" iconClass="heading" />
            {$theoryClasses.title}
        </h2>
        <p class="text-center mb-4"> {@html $theoryClasses.text}</p>
    </div>
    <div class="row mb-5 topics-row">
        <div class="col col-sm-12 col-lg-6">
            <div class="topics__category">
                <h3 class="topics__category__heading d-flex justify-content-start"><Icon iconName="background-wheel" iconClass="icon" /><span class="topics__category__heading-text">{$topics.title}</span></h3>
                <ol class="topics__category__items list-group list-group-numbered px-10 mx-5">
                    {#each topicsGen as topic, i}
                        <li class="list-group-item schedule d-flex py-3">
                            <span class="schedule__text">{topic}</span>
                        </li>
                    {/each}
                </ol>
            </div>
        </div>
        <div class="col col-sm-12 col-lg-6">
            <div class="topics__category">
                <h3 class="topics__category__heading d-flex justify-content-start"><Icon iconName="background-wheel" iconClass="icon" /><span class="topics__category__heading-text">{$topicsClassB.title}</span></h3>
                <ol class="topics__category__items list-group list-group-numbered px-10 mx-5">
                    {#each topicsB as topic, i}
                        <li class="list-group-item schedule d-flex py-3">
                            <span class="schedule__text">{topic}</span>
                        </li>
                    {/each}
                </ol>
            </div>
            <div class="topics__category">
                <h3 class="topics__category__heading d-flex justify-content-start"><Icon iconName="background-wheel" iconClass="icon" /><span class="topics__category__heading-text">{$topicsClassA.title}</span></h3>
                <ol class="topics__category__items list-group list-group-numbered px-10 mx-5">
                    {#each topicsA as topic, i}
                        <li class="list-group-item schedule d-flex py-3">
                            <span class="schedule__text">{topic}</span>
                        </li>
                    {/each}
                </ol>
            </div>
        </div>
    </div>
    {#if $supportViaApp }
        <div id="{$prefix}-section-theory-app" class="row theory-app">
            <HiddenAnchor markerName="{'theory-app'}" />
            <div class="col col-sm-12 col-lg-8 mt-3">
                <h2 class="text-center">
                    <Icon iconName="checklist-colored" iconClass="heading" />
                    {$supportViaApp.title}
                </h2>
                <p class="text-center mb-4"> {@html $supportViaApp.text}</p>
            </div>
            <div class="image-wrapper col col-lg-4 mt-3">
                <div class="illustration-wrapper text-center">
                    <Icon iconName="illustration-app" iconClass="fill" />
                </div>
            </div>
        </div>
    {/if}
</section>

<script lang="ts">
    import { assetUrl, supportViaApp, prefix, theoryClasses, i18n, topics, topicsClassA, topicsClassB } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

    $: schedules = $theoryClasses.schedules || [];
    $: topicsGen = $topics.items || [];
    $: topicsA = $topicsClassA.items || [];
    $: topicsB = $topicsClassB.items || [];
    const inViewport = ()=> {

    }

    onMount(() => {

    });



    // const unsubscribe = questions.subscribe(loadedQuestions => {
    //     console.log(loadedQuestions);
    // 	loadedQuestions.forEach( (q) => {
    // 		let extendQuestion = q;
    // 		extendQuestion.answers.forEach( (extendAnswer) => {
    // 			extendAnswer["selected"] = false;
    // 		});
    // 		savedAnswers.push(extendQuestion);
    // 	});
    // 	console.log(savedAnswers);
    // });

    // onDestroy(() => {
    // 	// 4. We make sure to unsubscribe from the store once the component has been destroyed.
    // 	unsubscribe();
    // });

</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection(#ffffff);
  }

  .image-wrapper {
    flex-grow: 0;
    padding-top: 3rem;
  }

  .content-wrapper {
    width: 100%;
    @include largeUp {
      width: 60%;
    }
    flex-grow: 0;
  }

  .schedules {
    width: 100%;
  }

  .schedule {
    &__text {
      margin-left: 2rem;
    }
  }

  .topics {
    &__category {
      margin-top: 2rem;
      &__heading {
        margin-bottom: 1rem;
        padding-left: 2rem;
        &-text {
          margin-left: 1rem;
        }
      }
    }
  }

  .theory-app {
    flex-direction: column;
    @include largeUp {
      flex-direction: row;
    }
  }
</style>
