<script>
	import { prefix } from '../stores.js';

	export let iconName;
	export let iconClass = '';


	$: iconClasses = $prefix + '-icon ' + $prefix + '-icon--' + iconClass;
</script>

<svg class="{iconClasses}">
    <use xlink:href="#x818973c5df76821320be3bb174285ef4-{iconName}" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
</svg>

<style type="text/scss">
    @import '../sass/vars';

	.#{$prefix}-icon {
		flex-shrink: 0;
		height: 48px;
		width: 48px;
	}

    .#{$prefix}-icon--menu {
      height: 40px;
      width: 40px;
      stroke: $ColorHighlight;
    }

    :global(.site-menu__item:hover) {
      .#{$prefix}-icon--menu {
        stroke: #ffffff;
      }
    }

    .#{$prefix}-icon--heading {
      height: 54px;
      width: 54px;
    }

    .#{$prefix}-icon--fill {
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    .#{$prefix}-icon--shrink {
      flex-shrink: 4;
      min-height: 24px;
      min-width: 24px;
      width: 100%;
      height: auto;
    }
</style>
