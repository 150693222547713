<section id="{$prefix}-section-impressum" class="section-container container-fluid shadow-sm">
    <HiddenAnchor markerName="{'impressum'}" />
    <h2 class="text-center">
        <Icon iconName="checklist-colored" iconClass="heading" />
        Impressum
    </h2>
    <div class="main container-fluid justify-content-between">
        <div class="inner container">
            <h4>Angaben gem&auml;&szlig: &sect; 5 TMG</h4><br />
            <p>Fahrschule Liah UG (haftungsbeschr&auml;nkt)<br /> Bahnhofstr 34 A<br/>59174 Kamen</p>

            <p>Handelsregister: HRB 11134<br />
                Registergericht: Amtsgericht Hamm</p>

            <p><strong>Vertreten durch:</strong><br/> Caner Yaylacioglu</p><br />

            <h4>Kontakt</h4><br />

            {#each contactDetails as contactDetail}
                <p class="pl-3">{@html contactDetail}</p>
            {/each}

            <br /><h4>Umsatzsteuer-ID</h4><br />

            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig: &sect; 27 a Umsatzsteuergesetz:<br/> DE361367118</p>
            <br />
            <h4>Aufsichtsbeh&ouml;rde</h4><br />
            <p>Kreis Unna<br/>
                Friedrich Ebert-Stra&szlig;e 17<br />
                59425 Weg</p>

            <p><a href="http://www.kreis-unna.de" target="_blank" rel="nopener Doesn't refer">http://www.kreis-unna.de</a> </p >

            <br /><h4>EU-Streitschlichtung</h4><br />
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit; <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        </div>
    </div>
</section>

<script lang="ts">
    import { assetUrl, contactForImpressum, prefix, contact, i18n } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import HiddenAnchor from '../components/HiddenAnchor.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

    $: contactDetails = $contactForImpressum || [];
    const inViewport = ()=> {

    }

    onMount(() => {

    });


</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection(#ffffff);
  }

  .main {
    justify-content: space-between;
    max-width: 100vw;
    word-break: break-word;
    margin-top: 2rem;
    margin-bottom: 2rem;
      &__left {

      }
      &__right {

      }
  }

  .inner {
    width: 100%;
    max-width: 820px;
  }


</style>
