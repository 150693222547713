<section id="{$prefix}-section-prices" class="section-container container-fluid shadow-sm">
    <HiddenAnchor markerName="{'prices'}" />
    <h2 class="text-center">
        <Icon iconName="checklist-colored" iconClass="heading" />
        {$prices.title}
    </h2>
    <p class="text-center mb-4"> {@html $prices.text}</p>
    <div class="row content-row">
        <div class="step col-sm-12 col-xl-6 price-info">
            <div class="d-flex justify-content-around flex-wrap flex-row">
                <Icon iconName="illustration-information" iconClass="fill" />
                <ul class="prices list-group flex-grow-1 m-4">
                    {#each items as item, i}
                        <li class="list-group-item price d-flex justify-content-start">
                            <Icon iconName="checked-box-white" iconClass="heading" />
                            <div>{@html item}</div>
                        </li>
                    {/each}
                </ul>
            </div>
        </div>
        <div class="step col-sm-12 col-xl-6 price-discount pt-5 pb-5">
            <div class="d-flex flex-wrap justify-content-around">
                <div class="price-discount__icon">
                    <Icon iconName="sale" iconClass="fill" />
                </div>
                <div class="price-discount__text flex-grow-1 border-top border-bottom">{@html $prices.sale}</div>
                <p class="sale-cta">
                    <Button class="hero__cta" buttonLink="{$prices.ctaAction}" buttonText={ $prices.ctaText } buttonClass="dark" buttonBlink="{true}" />
                </p>
            </div>
        </div>
    </div>
    <div class="background-filler d-flex justify-content-between">
        <Icon iconName="background-learn" iconClass="shrink" />
        <Icon iconName="background-wheel" iconClass="shrink" />
        <Icon iconName="background-finish" iconClass="shrink" />
        <Icon iconName="background-drive" iconClass="shrink" />
    </div>
</section>

<script lang="ts">
    import { assetUrl, language, prefix, prices, i18n } from '../stores.js';
    import { onMount, onDestroy } from 'svelte';
    import Icon from '../components/Icon.svelte';
    import Button from "../components/Button.svelte";
    import HiddenAnchor from '../components/HiddenAnchor.svelte';
    import { fade, scale, slide, fly } from 'svelte/transition';
    import { quartIn, quintIn } from 'svelte/easing';

    $: items = $prices.items || [];
    const inViewport = ()=> {

    }

    onMount(() => {

    });


</script>

<style type="text/scss">
  @import "../sass/vars.scss";
  @import "../sass/mixins.scss";

  .section-container {
    @include paddedPageSection(#ffffff);
  }

  .content-row {
    padding-top: 2rem;
  }

  .price-discount {
    &__icon {
      border: solid 2px $ColorPrimary;
      background: rgb(180,46,81);
      background: linear-gradient(90deg, rgba(180,46,81,1) 1%, rgba(151,56,113,1) 55%);
      border-radius: 50%;
      height: 80px;
      width: 80px;
      margin-right: 2rem;
      animation: spin 3s 3 normal;
      flex-shrink: 0;
      margin-bottom: 3rem;
    }
    &__text {
      font-size: 24px;
      padding-right: 1rem;
      // font-style: italic;
      color: $ColorHighlight;
      max-width: 70%;
      @include xLargeUp {
        font-size: 32px;
      }
    }
  }

  .background-filler {
    @include xLargeUp {
      margin-top: 4rem;
    }
  }

  .sale-cta {
    margin-top: 3rem;
  }

</style>
